<template>
  <DocumentsAISummaryChatMessage
    ref="summaryMessage"
    class="request-summary-message"
    :class="{ 'request-error': requestError }"
    :message-date="requestDate"
    :enable-actions="areActionsEnabledOnSummary"
    :summary-id="summaryId"
    :summary-feedback="summaryFeedback"
    :display-actions="areActionsDisplayed"
  >
   <AppTextWriter
      v-if="summaryId"
      :text="summaryText"
      @start="areActionsDisplayed = false"
      @end="areActionsDisplayed = true"
   ></AppTextWriter>
   <p v-else>{{ summaryText }}</p>
  </DocumentsAISummaryChatMessage>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import AppTextWriter from '@/common/AppTextWriter.vue'
import DocumentsAISummaryChatMessage from '@/project/documents/ai-summary/DocumentsAISummaryChatMessage.vue'
import aiSummariesService from '@/services/ai-summaries.service'

const TRANSLATION_KEY_PREFIX = 'project.documents.ai-summary.DocumentsAISummaryRequestChatMessage'

export default defineComponent({
  name: 'DocumentsAISummaryRequestChatMessage',
  components: { DocumentsAISummaryChatMessage, AppTextWriter },
  emits: ['error', 'success'],
  props: {
    documentId: {
      type: Number,
      required: true,
    },
    requestDate: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      areActionsDisplayed: true,
      messageResizeObserver: null,
      requestError: false,
      requestPending: false,
      summaryText: '',
      summaryId: null,
      summaryFeedback: 0,
    }
  },
  async mounted () {
    this.summaryText = this.$t(`${TRANSLATION_KEY_PREFIX}.requestSummaryDefaultText`)
    await this.requestSummary()
    const summaryMessageComponent = this.$refs.summaryMessage
    if (summaryMessageComponent) {
      this.messageResizeObserver = new ResizeObserver(this.scrollToSummaryEnd)
      this.messageResizeObserver.observe(summaryMessageComponent.$el)
    }
  },
  beforeDestroy () {
    if (this.messageResizeObserver) {
      this.messageResizeObserver.disconnect()
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    areActionsEnabledOnSummary () {
      return !this.requestPending && !this.requestError
    },
  },
  methods: {
    async requestSummary () {
      this.requestPending = true

      try {
        const { data } = await aiSummariesService.requestDocumentAISummary(this.roomMnemo, this.documentId)
        this.summaryText = data.summary
        this.summaryId = data.id
        this.summaryFeedback = data.feedback
        this.$emit('success')
      } catch (error) {
        console.error(error)
        const errorCode = error.response?.data?.errorCode === 'TOO_FEW_WORDS_DETECTED' ? 'tooFewWords' : 'default'
        this.summaryText = this.$t(`${TRANSLATION_KEY_PREFIX}.errors.${errorCode}`)
        this.requestError = true
        this.$emit('error')
      } finally {
        this.requestPending = false
      }
    },
    async scrollToSummaryEnd () {
      await this.$nextTick()
      const summaryMessage = this.$refs.summaryMessage
      summaryMessage?.$el?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
  },
})
</script>

<style scoped lang="scss">
.request-summary-message.request-error {
  ::v-deep .message-bubble {
    background-color: var(--v-error-lighten1);
    border: 1px solid var(--v-error-base);
  }
}
</style>
