import axios from 'axios'

export default {
  /**
   * @function getRoomByMnemo
   * @param {string} mnemo
   * @return AxiosInstance
   */
  getRoomByMnemo: function (mnemo, params) {
    return axios.get(`room/${mnemo}`, { params })
  },
  /**
   * @function postRoom
   * @param {Room} room
   * @return AxiosInstance
   */
  postRoom: function (room) {
    return axios.post('room', room)
  },
  /**
   * @function patchRoom
   * @param {string} mnemo - Room mnemo
   * @param {Object} object - Room properties to patch
   * @return AxiosInstance
   */
  patchRoom: function (mnemo, object) {
    return axios.patch(`room/${mnemo}`, object)
  },
  patchRoomInformation (mnemo, data) {
    return axios.patch(`/room/${mnemo}`, data)
  },
  getRoomProviders: function (mnemo) {
    return axios.get(`room/${mnemo}/providers`)
  },
  getRoomStats (mnemo) {
    return axios.get(`/room/${mnemo}/stats`)
  },
  getRoomNotifications (mnemo, config) {
    return axios.get(`/room/${mnemo}/notifications`, config)
  },
  closeRoom (mnemo, data) {
    return axios.post(`/room/${mnemo}/close`, data)
  },
  getSignatures (mnemo, params) {
    return axios.get(`/room/${mnemo}/envelopes`, { params })
  },
  chaseUsers (mnemo, envelopeId, data) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/chase/signers`, data)
  },
  chaseValidator (mnemo, envelopeId, data) {
    return axios.post(`/room/${mnemo}/envelope/${envelopeId}/chase/validators`, data)
  },
  updateAccessTime (mnemo) {
    return axios.post(`/room/${mnemo}/updateAccessDate/room`)
  },
  getRoomRights (mnemo) {
    return axios.get(`room/${mnemo}/rights`)
  },
  patchRoomGlobalRights (mnemo, data) {
    return axios.patch(`/room/${mnemo}/rights/global`, data)
  },
  patchRoomGroupRights (mnemo, groupId, data) {
    return axios.patch(`/room/${mnemo}/rights/group/${groupId}`, data)
  },
  patchRoomUserRights (mnemo, userId, data) {
    return axios.patch(`/room/${mnemo}/rights/user/${userId}`, data)
  },
  postRoomStorageAddon (mnemo, data) {
    return axios.post(`/room/${mnemo}/dataroom/addon`, data)
  },
  notifyPmNoMoreSpace (mnemo) {
    return axios.post(`/room/${mnemo}/request/dataroom/upgrade`)
  },
  notifyPmPlanDataroomFull (mnemo) {
    return axios.post(`/room/${mnemo}/notify/dataroom-plan/full`)
  },
  getRoomEnvelopesDownloadPreflight (mnemo, params) {
    return axios.get(`/room/${mnemo}/envelopeDownloadPreflight`, params)
  },
  getRoomNdaStatus (mnemo) {
    return axios.get(`room/${mnemo}/nda/self/status`)
  },
}
