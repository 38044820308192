<template>
  <div v-if="documentMenu.length > 0">
    <AppTooltip top>
      <template #activator="{attrs, on}">
        <div v-bind="attrs" v-on="on">
          <v-menu offset-y left min-width="150" :data-testid="`document-${document.id}-menu`">
            <template #activator="{ on, attrs }">
              <app-button type="icon" v-bind="attrs" data-locator="other-actions-button" v-on="on">
                <font-awesome-icon
                  :icon="['fas', 'ellipsis-vertical']"
                  style="font-size: 16px"
                ></font-awesome-icon>
              </app-button>
            </template>
            <v-list :data-testid="`document-${document.id}-menu-list`">
              <template v-for="(item, i) in documentMenu">
                <v-list-item
                  v-if="item.text !== 'divider'"
                  :key="`itemMenu-${i}`"
                  :data-testid="`document-${document.id}-item-${item.action}`"
                  @click="handleMenuAction(item.action)"
                >
                  <v-list-item-content>
                    <div class="d-flex justify-space-between align-center">
                      <span>{{ item.text }}</span>
                      <AppFeatureTag
                        v-if="item.featureTag"
                        :tag="item.featureTag"
                        class="ml-4"
                      />
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="item.text === 'divider'" :key="`divider-${i}`" />
              </template>
              <PolyOfficeListItemExport
                v-if="polyOfficeEnabled && document.type === 'file'"
                :fileSize="document.size"
                @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
              />
              <ArchiveAddAccessListItem
                v-if="document.type === 'file'"
                :routeOptions="archiveAddRouteOptions"
              />
            </v-list>
          </v-menu>
        </div>
      </template>
      <span>{{ $t('common.additionalActions') }}</span>
    </AppTooltip>
    <IManageFilePickerDialog
      :is-open.sync="imanageFilePickerDialogIsOpen"
      @files="preparePostFildersAsVersionToImanage"
    />
    <IManageFolderPickerDialog
      :is-open.sync="imanageFolderPickerDialogIsOpen"
      @folders="preparePostFildersToImanage"
    />
    <DocumentsNumberingDialog v-if="numberingDialogIsOpen"
                              :document="document"
                              @close="numberingDialogIsOpen = false"
                              @onSuccess="refreshDocuments"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppFeatureTag from '@/common/AppFeatureTag.vue'
import AppTooltip from '@/common/AppTooltip'
import ArchiveAddAccessListItem from '@/common/archives/ArchiveAddAccessListItem.vue'
import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog'
import IManageFolderPickerDialog from '@/common/imanage/IManageFolderPickerDialog'
import { DocumentsExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeListItemExport from '@/common/polyOffice/PolyOfficeListItemExport.vue'
import { isFileSignable } from '@/common/utils/files'
import DocumentsNumberingDialog from '@/project/documents/dialogs/DocumentsNumberingDialog.vue'
import { SIGNING_CHECKLIST_ADD_ROUTE_NAME } from '@/router'
import {
  POST_FILDERS_AS_VERSION_TO_IMANAGE,
  POST_FILDERS_TO_IMANAGE,
  GET_FOLDER_PATH,
  SET_TABLE_DOCUMENTS_PANE,
  SET_DOCUMENTS_VERSIONING_PANE,
} from '@/store/modules/documents/action_types'
import { OPEN_DOCUMENTS_DELETION_DIALOG } from '@/store/modules/documents-delete/action_types'
import { OPEN_DOCUMENT_RENAMING_DIALOG } from '@/store/modules/documents-rename/action_types'
import { SET_OPEN_LOGS_DIALOG } from '@/store/modules/logs/mutation_types'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsTableItemMenu',
  components: {
    AppFeatureTag,
    AppTooltip,
    ArchiveAddAccessListItem,
    DocumentsNumberingDialog,
    IManageFilePickerDialog,
    IManageFolderPickerDialog,
    PolyOfficeListItemExport,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      imanageFilePickerDialogIsOpen: false,
      imanageFolderPickerDialogIsOpen: false,
      numberingDialogIsOpen: false,
    }
  },
  inject: ['loadDocumentsTable'],
  computed: {
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('documents', ['isRecentFolder']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['currentUserRights', 'isDataRoom', 'roomMnemo', 'maxSignableSize']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    isTodoFolderOrChild () {
      return this.$store.getters['documentsBreadcrumb/hasTodoFolderOrChild']
    },
    documentMenu () {
      const menu = []

      const addMenuItem = (textKey, action, featureTag = null) => {
        menu.push({
          text: this.$t(textKey),
          action,
          ...(featureTag && { featureTag }),
        })
      }

      const addDivider = () => {
        menu.push({ text: 'divider' })
      }

      const addFolderMenuItems = () => {
        if (!this.isTodoFolderOrChild && this.document.canWrite) {
          ['move', 'rename', 'delete'].forEach(action =>
            addMenuItem(`project.documents.table.DocumentsTableItemMenu.${action}`, action),
          )
        }
        if (this.isPm) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.share', 'share')

          if (this.isDataRoom) {
            addMenuItem('project.documents.table.DocumentsTableItemMenu.seeLogs', 'logs')
          }

          if (this.document.numbering) {
            addMenuItem('project.documents.table.DocumentsTableItemMenu.numbering', 'numbering')
          }

          if (this.imanageEnabledAndLogged) {
            addMenuItem('project.documents.table.DocumentsTableItemMenu.exportToImanage', 'exportFolderToImanage')
          }
        }
      }

      const addFileMenuItems = () => {
        if (this.isPm) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.share', 'share')
        }
        if (this.isPm || this.currentUserRights.canUploadVersions) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.manageVersions', 'manageVersions')
        } else if (this.document.nbVersions > 1) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.showVersions', 'showVersions')
        }

        if (this.isPm && this.document.numbering) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.numbering', 'numbering')
        }

        if (this.document.canResume) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.summary', 'summary', 'beta')
        }

        if (this.isRecentFolder && this.document.isNew) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.openInFolder', 'openInFolder')
        }

        if (this.isPm && this.isDataRoom) {
          addMenuItem('project.documents.table.DocumentsTableItemMenu.seeLogs', 'logs')
        }

        if (this.isPm && isFileSignable(this.document, this.maxSignableSize) && !this.currentRoom.isDataroom) {
          addDivider()
          addMenuItem('project.documents.table.DocumentsTableItemMenu.signingChecklistAdd', 'signingChecklistAdd')
        }

        if (this.document.canWrite) {
          addDivider();
          ['move', 'duplicate', 'rename', 'delete'].forEach(action =>
            addMenuItem(`project.documents.table.DocumentsTableItemMenu.${action}`, action),
          )
        }

        if (this.imanageEnabledAndLogged) {
          ['exportToImanage', 'exportToImanageAsVersion'].forEach(action =>
            addMenuItem(`project.documents.table.DocumentsTableItemMenu.${action}`, action),
          )
        }
      }

      if (this.document.type === 'folder') {
        addFolderMenuItems()
      } else if (this.document.type === 'file') {
        addFileMenuItems()
      }

      return menu
    },
    archiveAddRouteOptions () {
      return {
        params: {
          files: [this.document],
        },
        query: {
          fileIds: this.document.id,
          fromMnemo: this.roomMnemo,
        },
      }
    },
  },
  methods: {
    ...mapActions('documents', [
      SET_TABLE_DOCUMENTS_PANE,
      SET_DOCUMENTS_VERSIONING_PANE,
      POST_FILDERS_TO_IMANAGE,
      POST_FILDERS_AS_VERSION_TO_IMANAGE,
      GET_FOLDER_PATH,
    ]),
    ...mapActions('documentsDelete', [OPEN_DOCUMENTS_DELETION_DIALOG]),
    ...mapActions('documentsRename', [OPEN_DOCUMENT_RENAMING_DIALOG]),
    ...mapActions('polyOffice', [GET_EXPORT_IFRAME]),
    ...mapMutations('documentsAISummary', ['SET_DOCUMENT_TO_SUMMARIZE_WITH_AI']),
    handleMenuAction (action) {
      switch (action) {
        case 'share':
          this.openPane('share')
          break
        case 'move':
          this.openPane('move')
          break
        case 'duplicate':
          this.openPane('duplicate')
          break
        case 'manageVersions':
          this.openVersioningPane('manage')
          break
        case 'showVersions':
          this.openVersioningPane('history')
          break
        case 'logs':
          this.$store.commit(`logs/${SET_OPEN_LOGS_DIALOG}`, this.document)
          break
        case 'delete':
          this.OPEN_DOCUMENTS_DELETION_DIALOG([this.document])
          break
        case 'rename':
          this.OPEN_DOCUMENT_RENAMING_DIALOG(this.document)
          break
        case 'signingChecklistAdd':
          this.$router.push({
            name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
            params: {
              files: [this.document],
            },
            query: {
              fileIds: this.document.id,
            },
          })
          break
        case 'exportFolderToImanage':
          this.imanageFolderPickerDialogIsOpen = true
          break
        case 'exportToImanage':
          this.imanageFolderPickerDialogIsOpen = true
          break
        case 'exportToImanageAsVersion':
          this.imanageFilePickerDialogIsOpen = true
          break
        case 'numbering':
          this.numberingDialogIsOpen = true
          break
        case 'openInFolder':
          this.$emit('openInFolder', this.document)
          break
        case 'summary':
          this.SET_DOCUMENT_TO_SUMMARIZE_WITH_AI(this.document)
          break
      }
    },
    openPane (tab) {
      this.SET_TABLE_DOCUMENTS_PANE({ isOpen: true, documents: [this.document], tab, forcePmSelection: true })
    },
    openVersioningPane (tab) {
      this.SET_DOCUMENTS_VERSIONING_PANE({ isOpen: true, documents: [this.document], tab })
    },
    async preparePostFildersToImanage (target) {
      const data = {
        targetId: target.selected,
      }
      if (this.document.type === 'file') {
        data.files = [this.document.id]
      } else if (this.document.type === 'folder') {
        data.folders = [this.document.id]
      }
      try {
        await this.POST_FILDERS_TO_IMANAGE({
          mnemo: this.roomMnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.documents.table.DocumentsTableItemMenu.postFildersToIManageSuccess'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.table.DocumentsTableItemMenu.postFildersToIManageError'),
        })
      }
    },
    async preparePostFildersAsVersionToImanage (target) {
      const data = {
        targetId: target.selected,
      }
      if (this.document.type === 'file') {
        data.fileId = this.document.id
      }
      try {
        await this.POST_FILDERS_AS_VERSION_TO_IMANAGE({
          mnemo: this.roomMnemo,
          data,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.documents.table.DocumentsTableItemMenu.postFildersToIManageSuccess'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.table.DocumentsTableItemMenu.postFildersToIManageError'),
        })
      }
    },
    onAddVersionClicked () {
      this.openVersioningPane('manage')
    },
    refreshDocuments () {
      this.loadDocumentsTable()
    },
    async polyOfficeOpenDialogHandler () {
      const payload = new DocumentsExport(
        this.roomMnemo,
        [this.document.id],
      )
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.documents.table.DocumentsTableItemMenu.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
  },
}
</script>
