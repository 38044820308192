<template>
  <div class="projects-folder-dropzone" @click="navigateToProjectsFolder">
    <AppDroppableArea class="pa-1"
                      :disabled="!canDropOnFolder"
                      @drop="onDrop">
      <AppCard height="100%"
              :hover="true"
              class="pa-5 h-100"
      >
        <v-row no-gutters class="d-flex align-center">
          <v-col class="text-h3 d-flex align-center overflow-hidden"
                style="line-height: 20px; max-height: 20px;">
            <app-icon size="regular"
                      icon-weight="fas"
                      icon-name="folder"
                      class="folder-icon mr-2"
            />
            <AppTooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs"
                      class="text-truncated"
                      v-on="on"
                >{{ folder.name }}</span>
              </template>
              {{ folder.name }}
            </AppTooltip>
          </v-col>
          <!-- Menu -->
          <ProjectsFoldersGridMenu :item="folder"></ProjectsFoldersGridMenu>
        </v-row>
      </AppCard>
    </AppDroppableArea>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'

  import AppDroppableArea from '@/common/AppDroppableArea.vue'
  import { FOLDER_ICON_COLOR, FOLDER_MAX_DEPTH } from '@/common/constants'
  import { DARK_BLUE_50, DARK_BLUE_500 } from '@/common/utils/colors'
  import projectsFoldersMixin from '@/mixins/projectsFoldersMixin'
  import { MOVE_ROOM_INTO_FOLDER } from '@/store/modules/projects-folders/action_types'
  import { MOVE_PROJECTS_FOLDER } from '@/store/modules/projects-folders-move/action_types'
  import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

  import ProjectsFoldersGridMenu from './ProjectsFoldersGridMenu.vue'
  import AppCard from '../common/AppCard.vue'
  import AppTooltip from '../common/AppTooltip.vue'

  export default {
    name: 'ProjectsFoldersGrid',
    components: { ProjectsFoldersGridMenu, AppCard, AppTooltip, AppDroppableArea },
    mixins: [projectsFoldersMixin],
    props: {
      folder: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        FOLDER_ICON_COLOR,
        DARK_BLUE_50,
        DARK_BLUE_500,
      }
    },
    computed: {
      ...mapState('projectsFolders', ['roomToMove']),
      ...mapState('projectsFoldersMove', ['projectsFolderToMove']),
      canDropOnFolder () {
        return (this.roomToMove.id || this.projectsFolderToMove.id) &&
               !this.isFolderDroppingOnItself && !this.isProjectsFolderToMoveMaxDepth
      },
      isFolderDroppingOnItself () {
        return this.projectsFolderToMove.id === this.folder.id
      },
      isProjectsFolderToMoveMaxDepth () {
        return this.projectsFolderToMove.maxDepth === FOLDER_MAX_DEPTH
      },
    },
    methods: {
      ...mapActions('projectsFolders', [MOVE_ROOM_INTO_FOLDER]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
      ...mapActions('projectsFoldersMove', [MOVE_PROJECTS_FOLDER]),
      onDrop () {
        if (this.roomToMove.id) {
          this.prepareMoveRoom()
        }
        if (this.projectsFolderToMove.id) {
          this.prepareMoveProjectsFolder()
        }
      },
      async prepareMoveRoom () {
        if (!this.roomToMove.id) return
        try {
          const { name, id } = this.roomToMove
          await this.MOVE_ROOM_INTO_FOLDER({
            toFolderId: this.folder.id,
            roomId: id,
          })

          this.updateCurrentAndDestinationFolders(true)

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('projects.dialogs.ProjectsMoveRoomDialog.moveRoomSuccess', {
            projectName: name,
            folderName: this.folder.name,
          }))
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.dialogs.ProjectsMoveRoomDialog.moveRoomError'))
        }
      },
      async prepareMoveProjectsFolder () {
        if (!this.canDropOnFolder) return

        const { name } = this.projectsFolderToMove
        try {
          await this.MOVE_PROJECTS_FOLDER({
            projectsFolderToMoveId: this.projectsFolderToMove.id,
            toFolderId: this.folder.id,
          })

          this.updateCurrentAndDestinationFolders()

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('projects.dialogs.ProjectsFoldersMoveFolderDialog.moveFolderSuccess', {
            folderName: name,
            destinationFolderName: this.folder.name,
          }))
        } catch (e) {
          if (e.response?.data?.errorCode === 'TOO_MANY_PARENT') {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.dialogs.ProjectsFoldersMoveFolderDialog.moveFolderErrorTooManyParent', {
              folderName: name,
            }))
          } else {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.dialogs.ProjectsFoldersMoveFolderDialog.moveFolderError'))
          }
        }
      },
    },
  }
</script>

<style scoped>
  .folder-icon {
    color: v-bind(FOLDER_ICON_COLOR)
  }

  .projects-folder-dropzone > .drag-in-progress {
    background-color: v-bind(DARK_BLUE_50) !important;
    outline: 2px dashed v-bind(DARK_BLUE_500) !important;
  }
</style>
