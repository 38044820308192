import {
  SET_PROJECTS_FOLDERS_TREE,
  SET_GET_PROJECTS_FOLDERS_TREE_PENDING,
  SET_CURRENT_PROJECTS_FOLDER,
  UPDATE_PROJECTS_FOLDERS_TREE,
  SET_PROJECTS_FOLDER_BY_ID_PENDING,
  SET_ROOM_TO_MOVE,
  SET_MOVE_ROOM_DIALOG_IS_OPEN,
  SET_MOVE_ROOM_PENDING,
} from './mutation_types'

export const mutations = {
  [SET_GET_PROJECTS_FOLDERS_TREE_PENDING] (state, isPending) {
    state.getProjectsFoldersTreePending = isPending
  },
  [SET_PROJECTS_FOLDERS_TREE] (state, foldersTree) {
    state.projectsFoldersTree = foldersTree
  },
  [UPDATE_PROJECTS_FOLDERS_TREE] (state, currentFolder) {
    const updateProjectsFoldersTree = (folders) => {
      return folders.map((folder) => {
        if (folder.id === currentFolder.id) {
          return currentFolder
        } else if (folder.children?.length > 0) {
          return {
            ...folder,
            children: updateProjectsFoldersTree(folder.children),
          }
        } else {
          return folder
        }
      })
    }

    state.projectsFoldersTree = updateProjectsFoldersTree(state.projectsFoldersTree)
  },
  [SET_PROJECTS_FOLDER_BY_ID_PENDING] (state, isPending) {
    state.projectsFolderByIdPending = isPending
  },
  [SET_CURRENT_PROJECTS_FOLDER] (state, folder) {
    state.currentProjectsFolder = folder
  },
  [SET_ROOM_TO_MOVE] (state, roomToMove) {
    state.roomToMove = roomToMove
  },
  [SET_MOVE_ROOM_DIALOG_IS_OPEN] (state, value) {
    state.moveRoomDialogIsOpen = value
  },
  [SET_MOVE_ROOM_PENDING] (state, value) {
    state.moveRoomPending = value
  },
}
