import axios from 'axios'

export default {
  createNewFolder (data) {
    return axios.post('user/self/folder', data)
  },
  getProjectsFolderById (folderId) {
    if (folderId) {
      return axios.get(`/user/self/folder/${folderId}`)
    }
    return axios.get('/user/self/folders')
  },
  renameProjectsFolder (projectsFolderToRenameId, newProjectsFolderName) {
    return axios.patch(`/user/self/folder/${projectsFolderToRenameId}`, { name: newProjectsFolderName })
  },
  moveProjectsFolderToFolder (projectsFolderToMoveId, toFolderId) {
    return axios.patch(`/user/self/folder/${projectsFolderToMoveId}`, { toFolderId })
  },
  moveRoomToFolder (projectsFolderToMoveIntoId, roomId) {
    return axios.post(`/user/self/folder/${projectsFolderToMoveIntoId}`, { roomId })
  },
  deleteProjectsFolder (projectsFolderToDeleteId) {
    return axios.delete(`/user/self/folder/${projectsFolderToDeleteId}`)
  },
}
