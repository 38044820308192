<template>
  <AppDialog :is-open="validationModalIsOpen"
             size="xl"
             @cancel="resetAndClose"
  >
    <template #title>{{$t(`${TRANSLATION_KEY_PREFIX}.title`)}}</template>
    <template #body>
      <template v-if="signaturesConfirmed">
        <div class="d-flex flex-column row-gap-4 align-center">
          <svg style="width: 90px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle
              class="path circle"
              fill="none"
              stroke="#23AE73"
              stroke-width="6"
              stroke-miterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              class="path check"
              fill="none"
              stroke="#23AE73"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
          <i18n :path="`${TRANSLATION_KEY_PREFIX}.redirection`" tag="p" scope="global">
            <template #pageName>
              <span class="font-weight-bold">{{ $t(`${TRANSLATION_KEY_PREFIX}.redirectionPageName`) }}</span>
            </template>
          </i18n>
        </div>
      </template>
      <template v-if="!signaturesConfirmed">
        <i18n :path="`${TRANSLATION_KEY_PREFIX}.fillConfirmationCode`" tag="p" scope="global">
          <template #phoneNumber>
            <span class="font-weight-bold">{{ profile.telephone }}</span>
          </template>
        </i18n>
        <div class="d-flex align-center column-gap-4 w-50 my-4">
          <app-icon icon-name="key" icon-weight="far" size="large"></app-icon>
          <v-otp-input v-model.trim="codeValidationInput"
                      length="6"
          />
        </div>
        <template v-if="displayValidationError">
          <p class="error--text">
            {{ displayValidationError }}
          </p>
        </template>
        <v-checkbox v-model="confirmeInformation"
                    class="body-2 text--primary"
        >
          <template v-slot:label>
            <i18n :path="`${TRANSLATION_KEY_PREFIX}.confirmeInformation`" tag="p" scope="global">
              <template #buttonLabel>
                <span class="font-weight-bold">{{ $t(`${TRANSLATION_KEY_PREFIX}.confirmeInformationButtonLabel`) }}</span>
              </template>
            </i18n>
          </template>
        </v-checkbox>
      </template>
    </template>
    <template #footer>
      <div class="d-flex justify-space-between align-center w-100">
        <div class="d-flex align-center">
          <template v-if="!signaturesConfirmed">
            <app-button type="text"
                        class="resendButton"
                        :disabled="!displaySmsResend || smsResent"
                        @click="prepareResendSms"
            >
              <span>{{ $t(`${TRANSLATION_KEY_PREFIX}.codeNotReceived`) }}</span>
              <template v-if="!smsResent && !displaySmsResend">
                <span v-if="smsResentTimer">({{ smsResentTimer }})</span>
              </template>
            </app-button>
            <template v-if="smsResent && displaySmsResend">
              <app-icon icon-name="check" class="success--text" />
            </template>
          </template>
        </div>
        <div class="d-flex column-gap-4">
          <app-button type="outlined" variant="neutral" :disabled="signaturesConfirmed" @click="resetAndClose" >
            {{ $t('common.cancel') }}
          </app-button>
          <app-button :disabled="signDisabled"
                      :loading="signDocumentPending"
                      @click="prepareSignDocument"
          >
            {{ $t(`${TRANSLATION_KEY_PREFIX}.sign`) }}
          </app-button>
        </div>
      </div>
    </template>
  </AppDialog>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { mapState, mapActions } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { SIGN_LANDING_PAGE_ROUTE_NAME } from '@/router'
import { RESEND_VALIDATION_SMS, SEND_VALIDATION_SMS, SIGN_DOCUMENT } from '@/store/modules/certeurope-sign/action_types'
import { SET_VALIDATION_MODAL_IS_OPEN } from '@/store/modules/certeurope-sign/mutation_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const TRANSLATION_KEY_PREFIX = 'certeurope-sign.CerteuropeSignValidationDialog'

export default {
  name: 'CerteuropeSignValidationDialog',
  components: { AppDialog },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
      signaturesConfirmed: false,
      codeValidationInput: '',
      confirmeInformation: false,
      smsResent: false,
      displaySmsResend: false,
      smsResentTimer: null,
      smsResendTimeout: null,
      wrongValidationSms: false,
      wrongValidationSms3Times: false,
    }
  },
  computed: {
    ...mapState('certeuropeSign', ['validationModalIsOpen', 'signingView', 'signDocumentPending']),
    ...mapState('user', ['profile']),
    displayValidationError () {
      if (this.wrongValidationSms) {
        return this.$t(`${TRANSLATION_KEY_PREFIX}.wrongValidationCode`)
      }
      if (this.wrongValidationSms3Times) {
        return this.$t(`${TRANSLATION_KEY_PREFIX}.wrongValidationCode3Times`)
      }
      return null
    },
    signDisabled () {
      return !this.codeValidationInput || !this.confirmeInformation || this.signDocumentPending || this.signaturesConfirmed
    },
  },
  watch: {
    smsResentTimer: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.smsResentTimer--
          }, 1000)
        } else if (value !== null) {
          this.displaySmsResend = true
        }
      },
      immediate: true,
    },
    validationModalIsOpen (value) {
      if (value) {
        this.onDialogOpen()
      }
    },
  },
  methods: {
    ...mapActions('certeuropeSign', [SEND_VALIDATION_SMS, SIGN_DOCUMENT, RESEND_VALIDATION_SMS]),
    onDialogOpen () {
      this.prepareSendValidationSms()
    },
    async prepareSendValidationSms () {
      try {
        if (this.smsResentTimer === null || this.smsResentTimer === -1) {
          this.smsResentTimer = 30
        }
        this.smsResendTimeout = setTimeout(() => { this.displaySmsResend = true }, 30000)
        if (this.smsSent) return
        this.smsSent = true
        this.wrongValidationSms = false
        await this.SEND_VALIDATION_SMS({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
          data: { consent: true },
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t(`${TRANSLATION_KEY_PREFIX}.sendValidationSmsError`),
        })
      }
    },
    async prepareResendSms () {
      try {
        this.wrongValidationSms = false
        this.smsResent = true
        setTimeout(() => {
          this.smsResent = false
        }, 20000)
        await this.RESEND_VALIDATION_SMS({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t(`${TRANSLATION_KEY_PREFIX}.sendValidationSmsError`),
        })
      }
    },
    async prepareSignDocument () {
      try {
        await this.SIGN_DOCUMENT({
          mnemo: this.mnemo,
          envelopeId: this.signingView.id,
          data: { otp: this.codeValidationInput },
        })
        this.signaturesConfirmed = true
        setTimeout(() => {
          this.$router.push({
            name: SIGN_LANDING_PAGE_ROUTE_NAME,
            params: {
              mnemo: this.mnemo,
            },
            query: {
              event: 'signing_complete',
            },
          })
        }, 3000)
      } catch (error) {
        // Debug info for V2-677
        let tagsInfo = ''
        this.signingView.pages.forEach((page) => {
          if (page.tags.length > 0) {
            tagsInfo += `--\nPage ${page.number} (id: ${page.id}) tags: \n`
            page.tags.forEach((tag) => {
              tagsInfo += `  - id: ${tag.id} type: ${tag.type} isSigned: ${tag.isSigned}\n`
            })
            tagsInfo += '--\n'
          }
        })
        console.info(tagsInfo)
        Sentry.captureException(error)

        if (['ERR_WRONG_OTP', 'ERROR_SIGN_SERVICE'].includes(error.response?.data?.errorCode)) {
          this.wrongValidationSms = true
        }

        if (error.response?.data?.errorCode === 'ERR_WRONG_OTP_RESENT') {
          this.wrongValidationSms3Times = true
        }
      }
    },
    resetAndClose () {
      clearTimeout(this.smsResendTimeout)
      this.smsResendTimeout = null
      this.smsResent = false
      this.signaturesConfirmed = false
      this.confirmeInformation = false
      this.wrongValidationSms = false
      this.displaySmsResend = false
      this.smsResentTimer = null
      this.codeValidationInput = ''
      this.$store.commit(`certeuropeSign/${SET_VALIDATION_MODAL_IS_OPEN}`, false)
    },
  },
}
</script>

<style scoped lang="scss">
.resendButton:disabled {
  cursor: not-allowed !important;
}

::v-deep .v-input__slot {
  align-items: start;
}

::v-deep .v-otp-input .v-input .v-input__control .v-input__slot {
  padding: 8px;
  max-width: 40px;
  margin-bottom: 0;
  min-height: 48px;
  border-radius: 8px;
}
</style>
