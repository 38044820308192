<template>
  <div class="AppIcon-container"
       :class="`AppIcon-container--${size}`"
       v-on="$listeners"
  >
    <font-awesome-icon :icon="[iconWeight, iconName]"
                       v-bind="$attrs"
                       class="AppIcon-icon"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

type AppIconWeight = 'fal' | 'far' | 'fas' | 'fad'
type AppIconSize = 'regular' | 'medium' | 'small' | 'large'

/**
 * @displayName Basic Icon
 */
export default defineComponent({
  name: 'AppIcon',
  props: {
    iconWeight: {
      type: String as PropType<AppIconWeight>,
      default: 'far',
    },
    iconName: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<AppIconSize>,
      default: 'regular',
    },
  },
})
</script>

<style scoped lang="scss">
.AppIcon {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    .AppIcon-icon {
      width: 18px;
      height: 18px;
    }

    &--large {
      width: 32px;
      height: 32px;
      .AppIcon-icon {
        width: 24px;
        height: 24px;
      }
    }

    &--medium {
      width: 20px;
      height: 20px;
      .AppIcon-icon {
        width: 16px;
        height: 16px;
      }
    }

    &--small {
      width: 16px;
      height: 16px;
      .AppIcon-icon {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
